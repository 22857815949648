import {MedicinesEnum} from "../../../Medicines.enum";
import {FaChevronLeft} from "react-icons/fa";
import {Collapse} from "react-daisyui";

type PresentationsProps = {
  setSelectedTab: (tab: MedicinesEnum) => void;
}
export default function Presentations({setSelectedTab}: PresentationsProps) {
  return (
    <div className="flex flex-col my-8">
      <div className="grid grid-cols-12  px-4">
        <div className="col-span-3">
          <button
            onClick={() => setSelectedTab(MedicinesEnum.MENU)}
            className="flex items-center justify-center gap-2">
            <FaChevronLeft className="w-8 h-8"/>
            <p>Regresar</p>
          </button>
        </div>
        <div className="col-span-9 ml-14 mt-6">
          <div className="h-20 w-20 p-4 bg-white rounded-lg shadow flex items-center justify-center">
            <img src={"./imgs/icons/icon-5.png"} alt="icon.1" className=""/>
          </div>
        </div>
      </div>
      <h2 className="font-bold text-xl text-center mt-2">PRESENTACIONES</h2>
      <Collapse
        icon="arrow"
        className="border-b border-gray-500"
      >
        <Collapse.Title className="text-xl font-medium text-orange-500">
          PRESENTACIONES
        </Collapse.Title>
        <Collapse.Content className="flex flex-col gap-4">
            <p>Caja con 10, 15, 20, 30, 50 y 60 tabletas de 500 mg
            </p>

        </Collapse.Content>
      </Collapse>
    </div>
  );
}