import {Route, Routes} from 'react-router-dom';
import {LoginPage} from "./pages/Auth/Login.page";
import {WelcomePage} from "./pages/Welcome/Welcome.page";
import Dashboard from "./pages/Dashboard/Dashboard.page";
import Oxetol from "./pages/Medicines/Oxetol/Oxetol.page";
import Sunam from "./pages/Medicines/Sunam/Sunam.page";
import Miraji from "./pages/Medicines/Miraji/Miraji.page";
import Sundonnez from "./pages/Medicines/Sundonnez/Sundonnez.page";
import Langinyl from "./pages/Medicines/Langinyl/Levipil.page";
import Levipil from "./pages/Medicines/Langinyl/Levipil.page";
import Zunun from "./pages/Medicines/Zunun/Zunun.page";
import Tejaswi from "./pages/Medicines/Tejaswi/Tejaswi.page";

export default function Router() {


  return (
    <Routes>
      {/* PUBLIC ROUTES*/}
      <Route path="/" element={<WelcomePage/>}/>
      <Route path="/login" element={<LoginPage/>}/>
      <Route path="/oxetol" element={<Oxetol/>}/>
        <Route path="/sunam" element={<Sunam/>}/>
        <Route path="/miraji" element={<Miraji/>}/>
        <Route path="/sundonnez" element={<Sundonnez/>}/>
        <Route path="/langinyl" element={<Langinyl />}/>
        <Route path="/levipil" element={<Levipil />}/>
        <Route path="/zunun" element={<Zunun />}/>
        <Route path="/tejaswi" element={<Tejaswi />}/>


        {/* PRIVATE ROUTES*/}
      <Route path="/dashboard" element={<Dashboard/>}/>
    </Routes>
  );
}